import React, { useState, useEffect } from "react";
import chevronLeft from "../../../assets/svg/chevron-down-gray.svg";
import InputField from "../../../components/shared/InputField/InputField";
import { useNewJobFormik } from "./useNewJobFormik";
import TextAreaInput from "../../../components/shared/TextAreaInput/TextAreaInput";
import closeGray from "../../../assets/svg/close-gray.svg";
import Button from "../../../components/shared/Button/Button";
import info from "../../../assets/svg/info.svg";
import SelectBox from "../../../components/shared/SelectBox/SelectBox";
import DropdownSearch from "../../../components/shared/DropdownSearch/DropdownSearch";
import MultiFileUploader from "../../../components/shared/MultiFileUploader/MultiFileUploader";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import { getJobTitles, getSubscription, createNewJob } from "../../../api/Jobs";
import { useTranslation } from "react-i18next";
import { getOrganizationUnits } from "../../../api/Organization";
import { apiCall } from "../../../api/Api";
import { useNavigate, useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { PhotoProvider, PhotoView } from "react-image-previewer";
import {
  SlideArrowLeft,
  SlideArrowRight,
  CloseButton,
} from "react-image-previewer/ui";
import DayPicker from "../../../components/shared/DayPicker/DayPicker";
import SubscribeForJobsModal from "../../../components/shared/SubscribeForJobsModal/SubscribeForJobsModal";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";
import calendarIconShort from "../../../assets/svg/calendar-icon2.svg";
import calendarIconLong from "../../../assets/svg/calendar-icon-full.svg";
import plusIcon from "../../../assets/svg/plus-icon-gray.svg";
import chevronDown from "../../../assets/svg/chevron-down-gray.svg";

import "./NewJobForm.scss";
import JobPayment from "../../../components/shared/JobPayment/JobPayment";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";

type datesType = {
  start_time: Date;
  end_time: Date;
};

const NewJobForm = () => {
  const { t } = useTranslation("common");
  let selectedLanguage = localStorage.getItem("lang");
  const [selectedUnit, setSelectedUnit] = useState<any>({
    name: "",
    value: "",
  });
  const [selectedJob, setSelectedJob] = useState("");
  const [selectJobItem, setSelectedJobItem] = useState<any>();
  const [shifts, setShifts] = useState<datesType[]>([]);
  const [jobTitles, setJobTitles] = useState<any>();
  const [businessUnits, setBusinessUnits] = useState<[]>([]);
  const [selectError, setSelectError] = useState({
    name: "",
    active: false,
  });
  //add image state
  const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);
  const [base64Images, setBase64Images] = useState<File[]>([]);
  const [base64ImageError, setBase64ImageError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffSet] = useState(0);
  const [nextPage, setNextPage] = useState<any>("");
  const [subscription, setSubscription] = useState<any>();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  //DEBAUNCER
  const [filter, setFilter] = useState<string>("");
  const [debouncedFilter] = useDebounce(filter, 500);
  const [isLoaded, setIsLoaded] = useState(false);
  const [scheduleVisibility, setScheduleVisibility] = useState("both");
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();
  const [showJobPayment, setShowJobPayment] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  useEffect(() => {
    try {
      getJobTitles("", limit, offset).then((res) => {
        if (selectedLanguage === "SQ") {
          const allJobTitles: string[] = res?.data?.results.map(
            (item: any) => item?.title?.sq,
          );
          setJobTitles([...allJobTitles]);
          setSelectedJobItem(res?.data?.results);
        } else {
          const allJobTitles: string[] = res?.data?.results.map(
            (item: any) => item?.title?.en,
          );
          setJobTitles([...allJobTitles]);
          setSelectedJobItem(res?.data?.results);
        }
        if (res.data?.next !== null) {
          setNextPage(res.data?.next);
        }
      });
      getOrganizationUnits().then((res) => {
        setBusinessUnits(res.data);
      });
      // getSubscription().then((res) => {
      //   if (res.data) {
      //     setSubscription(res.data);
      //     setShiftsAllowed(res.data[0]?.plan?.shifts_per_job_limit);
      //     setSeatsAllowed(res.data[0]?.plan?.seats_per_job_limit);
      //   }
      // });
    } catch (err) {}
  }, []);

  const loadMore = () => {
    let resultUrl = nextPage.split("?")[1];
    apiCall("GET", `job-classification/job-titles/?${resultUrl}`)
      .then((res) => {
        if (selectedLanguage === "SQ") {
          const allJobTitles: string[] = res?.data?.results.map((item: any) => {
            if (!jobTitles?.includes(item?.title?.sq)) {
              return item?.title?.sq;
            }
          });
          setJobTitles([...jobTitles, ...allJobTitles]);
          setSelectedJobItem([...selectJobItem, ...res?.data?.results]);
        } else {
          const allJobTitles: string[] = res?.data?.results.map((item: any) => {
            if (!jobTitles?.includes(item?.title?.en)) {
              return item?.title?.en;
            }
          });
          setJobTitles([...jobTitles, ...allJobTitles]);
          setSelectedJobItem([...selectJobItem, ...res?.data?.results]);
        }
        if (res.data?.next !== null) {
          setNextPage(res.data?.next);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const getJobTitleCall = (value: string) => {
      getJobTitles(value, 50)
        .then((res) => {
          if (selectedLanguage === "SQ") {
            const allJobTitles: string[] = res?.data?.results.map(
              (item: any) => item?.title?.sq,
            );
            const arrayOfNames = [...jobTitles, ...allJobTitles];
            setJobTitles([...new Set(arrayOfNames)]);
            setSelectedJobItem(res?.data?.results);
          } else {
            const allJobTitles: string[] = res?.data?.results.map(
              (item: any) => item?.title?.en,
            );
            const arrayOfNames = [...jobTitles, ...allJobTitles];
            setJobTitles([...new Set(arrayOfNames)]);
            setSelectedJobItem(res?.data?.results);
          }
          if (res.data?.next !== null) {
            setNextPage(res.data?.next);
          }
        })
        .catch((err) => {});
    };
    if (isLoaded) {
      getJobTitleCall(filter);
    }
    if (isLoaded && filter === "") {
      getJobTitles("", limit, offset).then((res) => {
        if (selectedLanguage === "SQ") {
          const allJobTitles: string[] = res?.data?.results.map(
            (item: any) => item?.title?.sq,
          );
          setJobTitles([...allJobTitles]);
          setSelectedJobItem(res?.data?.results);
        } else {
          const allJobTitles: string[] = res?.data?.results.map(
            (item: any) => item?.title?.en,
          );
          setJobTitles([...allJobTitles]);
          setSelectedJobItem(res?.data?.results);
        }
        if (res.data?.next !== null) {
          setNextPage(res.data?.next);
        }
      });
    }

    setIsLoaded(true);
  }, [debouncedFilter]);

  const formik = useNewJobFormik({
    onSubmit: async (values: any) => {
      setIsButtonLoading(true);
      const filterItem = selectJobItem.filter((item: any) => {
        if (selectedLanguage === "SQ") {
          return item?.title?.sq === selectedJob;
        } else {
          return item?.title?.en === selectedJob;
        }
      });

      let data = {
        job_title: filterItem ? filterItem[0]?.id : "",
        pay_rate: values.hourly_rate,
        description: values.description,
        organization_unit: selectedUnit?.id,
        image_data: base64Images,
        shifts: shifts,
        // is_active: true,
        latitude: selectedUnit?.latitude,
        longitude: selectedUnit?.longitude,
        seats: values.seats,
      };

      createNewJob(data)
          .then((res) => {
            const clientSecret = res.data.checkout_session.client_secret;
            setClientSecret(clientSecret); // Assuming you're managing state to control the modal visibility
            setShowJobPayment(true); // Show the JobPayment component
            setIsButtonLoading(false);
          })
          .catch((err) => {
            if (err?.response?.data?.code === "jobs_limit_reached") {
              setIsModalOpen(true);
            } else {
              const errorMessage = convertRestApiErrorCodes(
                  err?.response?.data?.code,
                  t,
              );
              globalCtx.openMessageModal("error", errorMessage);
            }
            setIsButtonLoading(false);
          });
    },
  });

// Render the JobPayment component conditionally
  return (
      <>
        {isModalOpen && (
            <SubscribeForJobsModal
                onUpgradeClick={() => {
                  navigate("/subscription");
                }}
                onCloseClick={() => {
                  setIsModalOpen(false);
                }}
            />
        )}

        {showJobPayment && clientSecret && (
            <ModalContainer position="top">
              <JobPayment
                  clientSecret={clientSecret}
                  onCloseClick={() => setShowJobPayment(false)}
              />
            </ModalContainer>
        )}

      <div className="NewJobForm">
        <div className="NewJobForm__header">
          <h1 className="NewJobForm__default-title">{t("jobs.title")}</h1>
          <div className="NewJobForm__header-wrapper">
            <img
              alt="chevron left icon"
              src={chevronLeft}
              className="NewJobForm__back-icon"
              onClick={() => navigate(-1)}
            />
            <h2 className="NewJobForm__title">{t("jobs.addNewJobs.title")}</h2>
          </div>
        </div>
        <div className="NewJobForm__form">
          <div className="NewJobForm__column">
            <div className="NewJobForm__subtitle">
              {t("jobs.addNewJobs.jobDetails.title")}
            </div>
            <div className="NewJobForm__input-container">
              <DropdownSearch
                items={jobTitles}
                selectedItem={selectedJob}
                onItemSelect={(item) => {
                  setSelectedJob(item);
                  setSelectError({ name: "Job", active: false });
                }}
                onBlur={() => {
                  if (selectedJob?.length === 0) {
                    setSelectError({ name: "Job", active: true });
                  }
                }}
                inputPlaceholder={t(
                  "jobs.addNewJobs.jobDetails.jobTitle.placeholder",
                )}
                className="NewJobForm__dropdown"
                loadMore={loadMore}
                setFilter={setFilter}
              />

              {selectError.active && selectError.name === "Job" && (
                <h5 className="NewJobForm__input-error">
                  {t("jobs.addNewJobs.jobDetails.jobTitle.error")}
                </h5>
              )}
            </div>
            <div className="NewJobForm__input-container">
              <InputField
                type="number"
                placeholder={t(
                  "jobs.addNewJobs.jobDetails.hourlyRate.placeholder",
                )}
                name="hourly_rate"
                value={formik.values.hourly_rate}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="NewJobForm__input"
                textIcon="&#8364;/h"
              />
              {formik.errors.hourly_rate && formik.touched.hourly_rate ? (
                <p className="NewJobForm__input-error">
                  {formik.errors.hourly_rate as string}
                </p>
              ) : (
                <div className="NewJobForm__seat-info">
                  <img
                    src={info}
                    alt="info icon"
                    className="NewJobForm__shift-info-icon"
                  />
                  <div className="NewJobForm__shift-info-text">
                    {t("jobs.addNewJobs.jobDetails.hourlyRate.min")}
                  </div>
                </div>
              )}
            </div>
            <div className="NewJobForm__input-container">
              <InputField
                type="number"
                placeholder={t("jobs.addNewJobs.jobDetails.seats.placeholder")}
                name="seats"
                value={formik.values.seats}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="NewJobForm__input"
                min="1"
                max="100"
              />
              {formik.errors.seats && formik.touched.seats ? (
                <p className="NewJobForm__input-error">
                  {formik.errors.seats as string}
                </p>
              ) : (
                <div className="NewJobForm__seat-info">
                  {/*<img*/}
                  {/*  src={info}*/}
                  {/*  alt="info icon"*/}
                  {/*  className="NewJobForm__shift-info-icon"*/}
                  {/*/>*/}
                  {/*<div className="NewJobForm__shift-info-text">*/}
                  {/*  {t("jobs.addNewJobs.jobDetails.seats.seatInfo.textOne")}{" "}*/}
                  {/*  {seatsAllowed}{" "}*/}
                  {/*  {seatsAllowed && seatsAllowed === 1*/}
                  {/*    ? t("jobs.addNewJobs.jobDetails.seats.seatInfo.textTwo")*/}
                  {/*    : t(*/}
                  {/*        "jobs.addNewJobs.jobDetails.seats.seatInfo.textThree",*/}
                  {/*      )}*/}
                  {/*  <div*/}
                  {/*    onClick={() => {*/}
                  {/*      setIsModalOpen(true);*/}
                  {/*    }}*/}
                  {/*    className="NewJobForm__upgrade-link"*/}
                  {/*  >*/}
                  {/*    {t("jobs.addNewJobs.jobDetails.seats.seatInfo.upgrade")}*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              )}
            </div>
            <div className="NewJobForm__input-container" onMouseOut={() => {}}>
              <SelectBox
                items={businessUnits}
                selectedItem={selectedUnit}
                onItemSelect={(item: any) => {
                  setSelectedUnit(item);
                  setSelectError({
                    name: "Unit",
                    active: false,
                  });
                }}
                placeholder={t(
                  "jobs.addNewJobs.jobDetails.businessUnit.placeholder",
                )}
                onClick={() => {}}
                onBlur={(e: any) => {
                  if (selectedUnit?.name?.length === 0) {
                    setSelectError({ name: "Unit", active: true });
                  }
                }}
                onChange={(e: any) => {}}
              />

              {selectError.active && selectError.name === "Unit" && (
                <h5 className="NewJobForm__input-error">
                  {t("jobs.addNewJobs.jobDetails.businessUnit.error")}
                </h5>
              )}
            </div>
            <div className="NewJobForm__input-container">
              <TextAreaInput
                placeholder={t(
                  "jobs.addNewJobs.jobDetails.description.placeholder",
                )}
                name="description"
                value={formik.values.description}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="NewJobForm__textarea"
              />
              {formik.errors.description && formik.touched.description && (
                <p className="NewJobForm__input-error">
                  {formik.errors.description as string}
                </p>
              )}
            </div>
            <div className="NewJobForm__images-label-wrapper">
              <div className="NewJobForm__images-label">
                {t("jobs.addNewJobs.jobDetails.images.title")}
              </div>
              <img
                src={info}
                alt="info icon"
                className="NewJobForm__info-icon"
              />
              <span className="NewJobForm__images-note">
                {" "}
                {t("jobs.addNewJobs.jobDetails.images.optional")}
              </span>
            </div>
            <PhotoProvider
              maskOpacity={0.7}
              overlayRender={(props) => {
                return (
                  <>
                    <SlideArrowLeft {...props} />
                    <SlideArrowRight {...props} />
                    <CloseButton onClick={props.onClose} />
                  </>
                );
              }}
            >
              <div className="NewJobForm__images">
                {base64Images?.length !== 0 &&
                  base64Images?.map((item: any, index: number) => (
                    <div
                      className="NewJobForm__image-preview-container"
                      key={index}
                    >
                      <PhotoView src={item} key={index}>
                        <img
                          alt="image preview"
                          src={item}
                          className="NewJobForm__image-preview"
                          style={{
                            width: "92px",
                            height: "92px",
                            objectFit: "cover",
                            borderRadius: "6px",
                          }}
                        />
                      </PhotoView>
                      <img
                        alt="delete icon"
                        src={deleteIcon}
                        className="NewJobForm__delete-icon"
                        onClick={() => {
                          const filterItems: any = base64Images?.filter(
                            (image) => image !== item,
                          );
                          setBase64Images(filterItems);
                        }}
                      />
                    </div>
                  ))}
                {base64Images.length < 10 && (
                  <div
                    onClick={() => setIsImageUploaderOpen(true)}
                    className="NewJobForm__images-placeholder"
                  >
                    <img
                      src={closeGray}
                      alt="plus icon"
                      className="NewJobForm__add-icon"
                    />
                  </div>
                )}
              </div>
            </PhotoProvider>
            {isImageUploaderOpen && (
              <MultiFileUploader
                accept="image/*"
                onCancel={() => {
                  setIsImageUploaderOpen(false);
                }}
                onSubmit={(images) => {
                  setBase64Images((prev: any) => [...prev, ...images]);
                  setIsImageUploaderOpen(false);
                  setBase64ImageError(false);
                }}
                allImages={base64Images}
                maxImageSize={10485760}
              />
            )}
          </div>
          <div className="NewJobForm__column">
            <div className="NewJobForm__shift-label">
              {" "}
              {t("jobs.addNewJobs.shifts.title")}
            </div>
            {scheduleVisibility === "both" && (
              <>
                <div
                  className="SchedulePlanButton"
                  onClick={() => {
                    setScheduleVisibility("shortTerm");
                  }}
                >
                  <div className="SchedulePlanButton__header">
                    <div className="SchedulePlanButton__header-left">
                      <img
                        className="SchedulePlanButton__calendar"
                        alt="pic"
                        src={calendarIconShort}
                      />
                      <div className="SchedulePlanButton__title">
                        Short-term <br />{" "}
                        <span>Less than 1 month duration</span>
                      </div>
                    </div>
                    <img
                      className="SchedulePlanButton__plus"
                      alt="pic"
                      src={plusIcon}
                    />
                  </div>
                  <div className="SchedulePlanButton__body">
                    <div className="SchedulePlanButton__paragraph">
                      • Optimized for maximum fill rate and responsiveness
                    </div>
                    <div className="SchedulePlanButton__paragraph">
                      • Used for one-off events, demand spikes and, last minute
                      replacements
                    </div>
                  </div>
                </div>
                <div
                  className="SchedulePlanButton"
                  onClick={() => {
                    setScheduleVisibility("longTerm");
                  }}
                >
                  <div className="SchedulePlanButton__header">
                    <div className="SchedulePlanButton__header-left">
                      <img
                        className="SchedulePlanButton__calendar"
                        alt="pic"
                        src={calendarIconLong}
                      />
                      <div className="SchedulePlanButton__title">
                        Long-term <br /> <span>More than 1 month duration</span>
                      </div>
                    </div>
                    <img
                      className="SchedulePlanButton__plus"
                      alt="pic"
                      src={plusIcon}
                    />
                  </div>
                  <div className="SchedulePlanButton__body">
                    <div className="SchedulePlanButton__paragraph">
                      • Optimized for consistent Pros
                    </div>
                    <div className="SchedulePlanButton__paragraph">
                      • Used for seasonal demand changes, temp to hire, and
                      fixed length projects
                    </div>
                  </div>
                </div>
              </>
            )}

            {scheduleVisibility === "shortTerm" && (
              <div className="ScheduleCard">
                <div className="ScheduleCard__header">
                  <img
                    alt="pic"
                    src={chevronDown}
                    className="ScheduleCard__chevron"
                    onClick={() => {
                      setScheduleVisibility("both");
                      setShifts([]);
                    }}
                  />
                  <div className="ScheduleCard__text">Short-term</div>
                </div>

                <div className="ScheduleCard__body">
                  <DayPicker
                    sendBackDates={(dates) => {
                      setShifts(dates);
                    }}
                  />
                </div>
              </div>
            )}

            {scheduleVisibility === "longTerm" && (
              <div className="ScheduleCard">
                <div className="ScheduleCard__header">
                  <img
                    alt="pic"
                    src={chevronDown}
                    className="ScheduleCard__chevron"
                    onClick={() => {
                      setScheduleVisibility("both");
                    }}
                  />
                  <div className="ScheduleCard__text">Long-term</div>
                </div>

                <div className="ScheduleCard__body">COMING SOON</div>
              </div>
            )}
          </div>
        </div>

        <Button
          label={t("jobs.addNewJobs.completeButton")}
          onClick={() => {
            formik.handleSubmit();
          }}
          className="NewJobForm__button"
          type="submit"
          disabled={
            shifts?.length === 0 ||
            !!formik.errors.description ||
            !!formik.errors.hourly_rate ||
            !!formik.errors.seats ||
            !!selectError.active ||
            isButtonLoading ||
            !formik.values.description ||
            !formik.values.hourly_rate ||
            !formik.values.seats ||
            selectedJob.length === 0 ||
            selectedUnit?.name.length === 0
          }
          isLoading={isButtonLoading}
        />
      </div>
    </>
  );
};

export default NewJobForm;
