export const calculateTimeDifference = (startTime: string, endTime: string) => {
  const [startHours, startMinutes] = startTime.split(":").map(Number);
  const [endHours, endMinutes] = endTime.split(":").map(Number);

  // Convert both times to total minutes
  const startTotalMinutes = startHours * 60 + startMinutes;
  let endTotalMinutes = endHours * 60 + endMinutes;

  // Handle the case where the end time is on the next day (past midnight)
  if (endTotalMinutes < startTotalMinutes) {
    endTotalMinutes += 24 * 60; // Add 24 hours worth of minutes to the end time
  }

  // Calculate the difference in total minutes
  let differenceInMinutes = endTotalMinutes - startTotalMinutes;

  // Convert the difference to hours and minutes
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  differenceInMinutes = differenceInMinutes % 60; // Remaining minutes after converting to hours

  // Format the result as HH:MM
  const formattedHours = String(differenceInHours).padStart(2, "0");
  const formattedMinutes = String(differenceInMinutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};
