export const addTimeToDate = (startDateString: Date, duration: string) => {
  // Convert startDateString into a Date object
  const startDate = new Date(startDateString);

  // Extract hours and minutes from the duration string "HH:MM"
  const [hoursToAdd, minutesToAdd] = duration.split(":").map(Number);

  // Add hours and minutes to the Date object
  startDate.setHours(startDate.getHours() + hoursToAdd);
  startDate.setMinutes(startDate.getMinutes() + minutesToAdd);

  return startDate; // Return the updated Date object
};
