import React from "react";
import { stripeApiKey } from "../../../stripeConfig";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import closeIcon from "../../../assets/svg/close-dark.svg";
import "./JobPayment.scss";

interface JobPaymentProps {
    clientSecret: string;
    onCloseClick: () => void;
}

const JobPayment = ({ clientSecret, onCloseClick }: JobPaymentProps) => {
    const options = { clientSecret };

    return (
        <div className="JobPayment">
            <div className="JobPayment__header">
                <p className="JobPayment__title">Finalize Payment</p>
                <img
                    alt="close"
                    className="JobPayment__close"
                    src={closeIcon}
                    onClick={onCloseClick}
                />
            </div>
            <div className="JobPayment__body">
                <EmbeddedCheckoutProvider stripe={stripeApiKey} options={options}>
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </div>
        </div>
    );
};

export default JobPayment;
