import React, { useCallback, useEffect, useRef, useState } from "react";

import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import { calculateTimeDifference } from "../../../lib/helpers/calculateTimeDifference";
import { addTimeToDate } from "../../../lib/helpers/addTimeToDate";
import TimePicker from "../TimePicker/TimePicker";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import calendarIcon from "../../../assets/svg/calendar-icon-gray.svg";
import closeIcon from "../../../assets/svg/close-dark.svg";
import plusIcon from "../../../assets/svg/plus-icon-blue.svg";

import cs from "classnames";

import "./DayPicker.scss";

type datesType = {
  start_time: Date;
  end_time: Date;
};

interface DayPickerProps {
  sendBackDates: (dates: datesType[]) => void;
}

const DayPicker = (props: DayPickerProps) => {
  const { sendBackDates } = props;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const calendarRef = useRef(null);
  const options = {
    weekday: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  const dateFormatter = new Intl.DateTimeFormat("en-US", options as any);

  const handleDayClick = (date: Date) => {
    const dateIndex = selectedDates.findIndex(
      (selectedDate) => selectedDate.toDateString() === date.toDateString(),
    );

    if (dateIndex === -1) {
      // Date is not in the array, add it and then sort
      setSelectedDates((prevDates) => {
        return [...prevDates, date].sort((a, b) => a.getTime() - b.getTime());
      });
    } else {
      // Date is already in the array, remove it
      setSelectedDates((prevDates) =>
        prevDates.filter((_, index) => index !== dateIndex),
      );
    }
  };

  const tileClassName = useCallback(
    ({ date }: { date: Date }) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to midnight to ensure comparison is just for the day

      if (date < today) {
        return "past-date"; // Add class for past dates
      }

      if (
        selectedDates.find(
          (selectedDate) => selectedDate.toDateString() === date.toDateString(),
        )
      ) {
        return "selected"; // Add a class for selected dates
      } else {
        return "unselected"; // Add a class for unselected dates
      }
    },
    [selectedDates],
  );

  useEffect(() => {
    if (selectedStartTime === "") {
      return;
    }
    if (selectedEndTime === "") {
      return;
    }

    setErrorMessage("");

    const timeDifference = calculateTimeDifference(
      selectedStartTime,
      selectedEndTime,
    );

    const [timeDifferenceHours, timeDifferenceMinutes] = timeDifference
      .split(":")
      .map(Number);

    if (
      timeDifferenceHours > 12 ||
      (timeDifferenceHours === 12 && timeDifferenceMinutes > 0)
    ) {
      setErrorMessage("Time difference cannot be more than 12 hours");
      return;
    }

    if (timeDifferenceHours < 3) {
      setErrorMessage("Time difference cannot be less than 3 hours");
      return;
    }

    const newDateArray: { start_time: Date; end_time: Date }[] = [];

    selectedDates.map((date) => {
      const startDate = new Date(date);
      const [startDateHours, startDateMinutes] = selectedStartTime
        .split(":")
        .map(Number);
      startDate.setHours(startDateHours);
      startDate.setMinutes(startDateMinutes);
      const endDate = addTimeToDate(startDate, timeDifference);

      newDateArray.push({
        start_time: startDate,
        end_time: endDate,
      });
    });

    if (newDateArray.length > 30) {
      setErrorMessage("You cannot select more than 30 dates");
      sendBackDates([]);
      return;
    }

    sendBackDates(newDateArray);
  }, [selectedDates, selectedStartTime, selectedEndTime]);

  useOutsideClickDetection(calendarRef, () => {
    setIsCalendarOpen(false);
  });

  return (
    <div className="DayPicker" ref={calendarRef}>
      <div
        className={cs(
          "DayPicker__dates",
          selectedDates.length === 0 && "DayPicker__dates--active",
        )}
        onClick={() => {
          if (selectedDates.length === 0) {
            setIsCalendarOpen(true);
          }
        }}
      >
        <div className="DayPicker__datesLabel">
          <img
            className="DayPicker__datesLabel-icon"
            src={calendarIcon}
            alt="pic"
          />
          <div className="DayPicker__datesLabel-text">
            {selectedDates.length === 0 ? "Select date(s)" : "Dates"}
          </div>
        </div>
        <div
          className={cs(
            "DayPicker__date-container",
            selectedDates.length !== 0 && "DayPicker__date-container--active",
          )}
        >
          {selectedDates.map((item, index) => (
            <div className="DayPicker__date" key={index}>
              <div className="DayPicker__date-dateAndMonth">
                {dateFormatter.format(item).split(",")[1]}
              </div>
              <div className="DayPicker__date-day">
                {dateFormatter.format(item).split(",")[0]}
              </div>

              <img
                alt="pic"
                src={closeIcon}
                className="DayPicker__date-close"
                onClick={() => {
                  handleDayClick(item);
                }}
              />
            </div>
          ))}

          {selectedDates.length !== 0 && (
            <div
              className="DayPicker__add"
              onClick={() => {
                setIsCalendarOpen(true);
              }}
            >
              <img alt="pic" src={plusIcon} className="DayPicker__add-plus" />
              <div className="DayPicker__add-text">Add dates</div>
            </div>
          )}
        </div>
      </div>
      {isCalendarOpen && (
        <div className="DayPicker__calendar">
          <Calendar
            minDate={new Date()}
            onClickDay={(date) => {
              handleDayClick(date);
            }}
            tileClassName={tileClassName}
          />
        </div>
      )}

      <div className="DayPicker__times">
        <TimePicker
          label="Start time"
          sendBackTime={(time) => {
            setSelectedStartTime(time);
          }}
        />
        <TimePicker
          label="End time"
          sendBackTime={(time) => {
            setSelectedEndTime(time);
          }}
        />
      </div>

      {errorMessage !== "" && (
        <h5 className="DayPicker__errorMessage">{errorMessage}</h5>
      )}
    </div>
  );
};

export default DayPicker;
