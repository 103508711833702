import React, { useEffect, useState } from "react";

import { apiCall } from "../../api/Api";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { convertRestApiErrorCodes } from "../../lib/helpers/handleErrors";
import { useGlobalContext } from "../../context/GlobalContext/GlobalContext";
import plusIcon from "../../assets/svg/plus-icon.svg";
import addUnitsCircle from "../../assets/svg/add-units-circle.svg";
import UnitCart from "./UnitCart/UnitCart";
import LoadingComponent from "../../components/shared/LoadingComponent/LoadingComponent";
import SubscribeForUnitsModal from "../../components/shared/SubscribeForUnitsModal/SubscribeForUnitsModal";

import "./Units.scss";

const Units = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [units, setUnits] = useState<any | null>();
  const [activePlan, setActivePlan] = useState<any | null>();
  const authCtx = useAuthContext();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();
  const localOrganization = useAuthContext().localOrganization;
  const globalCtx = useGlobalContext();

  useEffect(() => {
    apiCall("GET", "b2b/organization-unit/")
      .then((res) => {
        setUnits(res.data);
      })
      .catch((error) => {
        const errorMessage = convertRestApiErrorCodes(
          error?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
        setUnits(null);
      });
  }, []);

  // useEffect(() => {
  //   // GET ACTIVE SUBSCRIPTION PLANS
  //   apiCall("GET", "subscription/")
  //     .then((response) => {
  //       setActivePlan(response.data[0]);
  //     })
  //     .catch((error) => {
  //       const errorMessage = convertRestApiErrorCodes(
  //         error?.response?.data?.code,
  //         t,
  //       );
  //       globalCtx.openMessageModal("error", errorMessage);
  //       setActivePlan(null);
  //     });
  // }, []);

  const handleNewUnitNavigate = () => {
    if (units?.length >= activePlan?.plan?.organization_units) {
      setIsModalOpen(true);
    } else {
      navigate("/units/new-unit");
    }
  };

  const handleEditUnitNavigate = (unit: any) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("unit_id", unit.id);
    const newSearch = searchParams.toString();
    const newPath = `/units/edit-unit?${newSearch}`;
    navigate(newPath);
  };

  return (
    <>
      {isModalOpen && (
        <SubscribeForUnitsModal
          onUpgradeClick={() => {
            navigate("/subscription");
          }}
          onCloseClick={() => {
            setIsModalOpen(false);
          }}
          image={localOrganization.logo_url}
          name={localOrganization.name}
          number={10}
        />
      )}
      <div className="Units">
        <div className="Units__head">
          <p className="Units__head-title">{t("units.title")}</p>
        </div>
        <div className="Units__body">
          <>
            <div className="AddUnitsCart">
              <div className="AddUnitsCart__left">
                <p className="AddUnitsCart__title">
                  {t("units.addUnitsCart.title")}
                </p>
                <p className="AddUnitsCart__subtitle">
                  {t("units.addUnitsCart.description")}
                </p>
              </div>
              <div
                className="AddUnitsCart__button"
                onClick={() => {
                  handleNewUnitNavigate();
                }}
              >
                <div className="AddUnitsCart__button-round">
                  <img
                    alt="pic"
                    src={plusIcon}
                    className="AddUnitsCart__button-round--plus"
                  />
                </div>
                <p className="AddUnitsCart__button-text">
                  {t("units.addUnitsCart.button")}
                </p>
              </div>

              <img
                alt="pic"
                src={addUnitsCircle}
                className="AddUnitsCart__circle"
              />
            </div>{" "}
            {units === undefined ? (
              <div className="Units__loader">
                <LoadingComponent />
              </div>
            ) : units === null ? (
              <div className="Units__noData">{t("noData")}</div>
            ) : (
              <div className="Units__carts">
                {units?.map((item: any, index: number) => (
                  <UnitCart
                    key={index}
                    image={authCtx.localOrganization.logo_url as string}
                    name={item.name}
                    city={item.city}
                    address={item.address}
                    onEditClick={() => {
                      handleEditUnitNavigate(item);
                    }}
                    number={item.phone}
                  />
                ))}
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default Units;
